import { render, staticRenderFns } from "./comp_dpc-ProductsCarouselUnified.vue?vue&type=template&id=886d4f5e&scoped=true"
import script from "./comp_dpc-ProductsCarouselUnified.vue?vue&type=script&lang=js"
export * from "./comp_dpc-ProductsCarouselUnified.vue?vue&type=script&lang=js"
import style0 from "./comp_dpc-ProductsCarouselUnified.vue?vue&type=style&index=0&id=886d4f5e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "886d4f5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCol,VIcon,VRow,VTab,VTabItem,VTabs,VTabsItems})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
