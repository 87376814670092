<script>
import { get } from 'vuex-pathify'

export default {
	props: {
		data: Object,
	},
	data() {
		return {
			tab: 0,
			isIntersecting: false,
		}
	},
	methods: {
		textPosition(padding = true) {
			let res
			switch (this.data.bgTextPosition) {
				case 'top':
					res = 'justify-start'
					if (padding) res += ' pt-4'
					return res
				case 'mid':
					return 'justify-center'
				default:
					res = 'justify-end'
					if (padding) res += ' pb-4'
					return res
			}
		},
		onIntersect(entries) {
			this.isIntersecting = entries[0].isIntersecting
		},
		positionIs(position) {
			return this.data.position === position
		},
		offerDateToString(date) {
			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			return `La oferta termina el ${fecha}`
		},
	},
	computed: {
		offers: get('shop/limitedTimeOffer'),
		left() {
			return this.$b.d && this.positionIs('left')
		},
		right() {
			return this.$b.d && this.positionIs('right')
		},
		top() {
			return this.$b.mt || this.positionIs('top')
		},
		listId() {
			return `${this.data.collectionId}-${this.data.schemaKey}`
		},
		offersWithMatchingTab() {
			return this.offers?.filter((offer) =>
				this.data.tabs.some((tab) => {
					if (tab.selectType == 'collection') return offer.collectionId == tab.collectionId
					else return tab.products.some((p) => p.collectionsIds.some((x) => x == offer.collectionId))
				})
			)
		},
		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate
			return this.offerDateToString(date)
		},
		limitedTimeOffer() {
			if (this.data.useTabs && this.data.oneTimerPerTab) return

			if (this.data.useTabs) {
				return this.offers?.find((offer) =>
					this.data.tabs.some((tab) => tab.collectionId === offer.collectionId)
				)
			}

			return this.offers?.find((offer) => offer.collectionId == this.data.collectionId)
		},
		bgColor() {
			if (this.data.boardType == 'bg') {
				return { backgroundColor: this.data.bgColor }
			} else return null
		},

		hasBoardData() {
			if (this.data.boardEnabled) {
				if (this.data.boardType == 'product') return !!this.data.boardProduct
				else return true
			}
			return false
		},
		desktopSlidesPerPage() {
			if (this.hasBoardData && (!this.top || this.data.panelSize == 'horizontal')) return 2
			if (this.hasBoardData) return 3
			return 4
		},
		cardType() {
			return this.data.useCustomCard ? this.data.cardType : undefined
		},
		currentImage() {
			const key = this.$b.m
				? 'imgMobile'
				: this.data.panelSize == 'horizontal'
				? this.data.useTabs
					? 'imgHorizontalWithTabs'
					: 'imgHorizontal'
				: 'img'
			return this.data[key]
		},
		isVideo() {
			return (
				this.currentImage.type == 'video' ||
				this.currentImage.type == 'youtube-video' ||
				this.currentImage.type == 'vimeo-video'
			)
		},
		videoDimensions() {
			if (this.$b.d && this.currentImage.type == 'video' && this.data.panelSize == 'vertical') {
				return {
					width: '276px',
					height: '537px',
					'max-width': 'unset',
				}
			}
			return {}
		},
	},
	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container v-intersect.once="onIntersect">
		<Title
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row
			:class="{ 'flex-row-reverse': data.inverted && $b.td, 'flex-column-reverse': data.inverted && $b.m }"
		>
			<v-col
				v-if="hasBoardData"
				class="justify-center d-flex align-center"
				cols="12"
				:lg="data.panelSize == 'horizontal' ? 6 : 3"
			>
				<!-- ProductCard with timer -->
				<div v-if="data.boardType == 'product'" class="w100">
					<ProductCard
						:product="data.boardProduct"
						:list-id="listId"
						featured
						:position="data.inverted ? 'left' : 'right'"
						:timerProduct="!!limitedTimeOffer"
						:verticalCard="$b.mt ? false : true"
					/>
				</div>

				<!-- Image with timer -->
				<div v-else-if="data.boardType == 'img'" class="h100 w100" style="position: relative">
					<div v-if="isVideo" class="video-container d-flex justify-center align-center">
						<div class="d-flex flex-column align-center h100 w100">
							<Media
								v-bind="videoDimensions"
								id="video-background"
								:class="{
									'video-hosted-mobile': $b.m,
									'video-hosted': currentImage.type != 'video',
								}"
								:src="currentImage"
								:video-props="{
									videoAttrs: {
										autoplay: data.enableAutoplay,
										muted: !data.enableSound,
										loop: 'loop',
										controls: data.showControls,
									},
								}"
								:youtube-video-props="{
									iframeAttrs: {
										allow: 'autoplay; encrypted-media',
										allowfullscreen: 'allowfullscreen',
									},
									urlOptions: {
										controls: data.showControls ? 1 : 0,
										showinfo: 0,
										rel: 0,
										autoplay: data.enableAutoplay ? 1 : 0,
										mute: data.enableSound ? 0 : 1,
										loop: 1,
										playlist: currentImage.data.videoId,
									},
								}"
								:vimeo-video-props="{
									iframeAttrs: {
										allow: 'autoplay; encrypted-media',
									},
									urlOptions: {
										controls: data.showControls ? 1 : 0,
										muted: data.enableSound ? 0 : 1,
										autoplay: data.enableAutoplay ? 1 : 0,
										loop: 1,
									},
								}"
							>
							</Media>
						</div>
					</div>
					<SafeLink v-else :to="data.bgLink">
						<Media :src="currentImage" width="100%" :imgClass="{ 'rounded-lg': $b.td }">
							<div
								class="d-flex flex-column align-center h100 w100 px-6"
								:class="[
									{ 'rounded-lg': $b.td, 'background-overlay': data.bgImgOverlay },
									textPosition(),
								]"
							>
								<div v-if="data.useTabs && data.oneTimerPerTab">
									<div
										v-for="(offerTab, n) in offersWithMatchingTab"
										:key="n"
										v-show="offerTab.collectionId == data.tabs[tab].collectionId"
									>
										<LimitedTimeOfferCountdown :limitedTimeOffer="offerTab" timerCard />
										<div class="mt-2">
											<p
												class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
											>
												{{ offerDateToString(offerTab.untilDate) }}
											</p>
										</div>
									</div>
								</div>
								<div v-else-if="limitedTimeOffer">
									<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
									<div class="mt-2">
										<p
											class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
										>
											{{ limitedTimeOfferDate }}
										</p>
									</div>
								</div>
							</div>
						</Media>
					</SafeLink>

					<div class="overlay-content d-flex flex-column align-center h100 w100">
						<div
							class="d-flex flex-column align-center h100"
							:class="[textPosition(false)]"
							style="z-index: 2"
						>
							<h3
								class="font-2 font-sm-5 font-md-7 font-weight-bold white--text"
								:class="{ 'title-active': isIntersecting }"
								v-if="data.bgTitle"
							>
								{{ data.bgTitle }}
							</h3>
							<h4
								class="font-1 font-sm-3 font-md-5 font-weight-medium white--text"
								:class="{ 'subtitle-active': isIntersecting }"
								v-if="data.bgSubtitle"
							>
								{{ data.bgSubtitle }}
							</h4>
							<v-btn
								v-if="data.cta"
								class="cta font-weight-bold mt-5"
								:class="{ 'btn-active': isIntersecting, 'subtitle-active': isIntersecting }"
								:to="data.ctaLink"
								:small="$b.m"
								style="pointer-events: all"
							>
								{{ data.cta }}
							</v-btn>
						</div>
						<div
							v-if="data.bgImgOverlay"
							class="video-background-overlay"
							:class="{ 'rounded-lg': $b.td && !isVideo }"
							style="background-color: #424242aa"
						></div>
					</div>
				</div>

				<!-- BGColor with timer -->
				<SafeLink
					v-else-if="data.boardType == 'bg'"
					:to="data.bgLink"
					class="h100 w100"
					:style="bgColor"
					:class="{ 'rounded-lg': $b.td }"
				>
					<div
						class="d-flex flex-column align-center h100 w100 px-6 py-4"
						:class="[{ 'background-overlay': data.bgImgOverlay }, textPosition()]"
						style="min-height: 142.17px"
					>
						<div class="d-flex flex-column align-center" v-if="data.bgTitle">
							<h3
								class="font-weight-bold line-clamp-3 strong"
								:class="{ 'font-4': $b.m, 'font-5 mb-1': $b.td }"
							>
								{{ data.bgTitle }}
							</h3>

							<p
								v-if="data.bgSubtitle"
								class="font-weight-medium line-clamp-3 primary"
								:class="{ 'mb-1 font-2': $b.m, 'font-3': $b.td, 'mb-3': $b.d }"
							>
								{{ data.bgSubtitle }}
							</p>
							<v-btn
								v-if="data.cta"
								class="cta font-weight-bold mt-5"
								:class="{ 'btn-active': isIntersecting, 'subtitle-active': isIntersecting }"
								:to="data.ctaLink"
								:small="$b.m"
								>{{ data.cta }}</v-btn
							>
						</div>
						<div v-if="data.useTabs && data.oneTimerPerTab">
							<div
								v-for="offerTab in offersWithMatchingTab"
								:key="offerTab.collectionId"
								v-show="offerTab.collectionId == data.tabs[tab].collectionId"
							>
								<LimitedTimeOfferCountdown :limitedTimeOffer="offerTab" timerCard />
								<div class="mt-2">
									<p
										class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
									>
										{{ offerDateToString(offerTab.untilDate) }}
									</p>
								</div>
							</div>
						</div>

						<div v-else-if="limitedTimeOffer">
							<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard />
							<div class="mt-2">
								<p
									class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0"
								>
									{{ limitedTimeOfferDate }}
								</p>
							</div>
						</div>
					</div>
				</SafeLink>
			</v-col>

			<!-- PRODUCTS CAROUSEL -->
			<v-col
				cols="12"
				:md="hasBoardData ? (data.panelSize == 'horizontal' ? 6 : 9) : 12"
				v-if="!data.useTabs"
				class="no-mobile-px pa-0"
				style="min-width: 0; align-self: center"
			>
				<ProductsCarousel
					class="wide"
					:cardType="cardType"
					:products="data.products"
					:list-id="listId"
					arrowsBgHalfRounded
					v-bind="
						$b.props({
							slidesPerPage: {
								d: hasBoardData ? (data.panelSize == 'horizontal' ? 2 : 3) : 4,
								t: 3,
								m: 2,
							},
							gutter: { m: 12 },
						})
					"
				/>
			</v-col>
			<v-col v-else cols="12" :md="hasBoardData ? (data.panelSize == 'horizontal' ? 6 : 9) : 12">
				<v-row :class="{ 'flex-row-reverse': right }">
					<v-col :cols="top ? 12 : 2">
						<v-tabs
							show-arrows
							v-model="tab"
							:centered="top"
							:center-active="top"
							:vertical="$b.d && !top"
							:color="$vars.cta"
							class="h100"
							:class="{
								'slider-right': left && $b.d,
								'top-underline': top && $b.d,
								'slider-top': top,
								'px-2': !top && $b.m,
							}"
							:slider-color="$vars.cta"
							background-color="transparent"
						>
							<v-tab
								:class="{ 'justify-start': left, 'justify-end': right }"
								v-for="(item, n) in data.tabs"
								:key="n"
								class="base"
								active-class="cta--text"
							>
								<v-icon v-if="data.titleType == 'icon'">{{ item.titleIcon }}</v-icon>
								<Media v-else-if="data.titleType == 'img'" :src="item.titleImg" />
								<span v-else>
									{{ item.title }}
								</span>
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col
						:cols="top ? 12 : 10"
						class="no-mobile-px pa-0"
						:class="{ 'border-left': left, 'border-right': right }"
					>
						<v-tabs-items v-model="tab" touchless style="background: transparent !important">
							<v-tab-item v-for="(item, n) in data.tabs" :key="n" class="pa-1">
								<ProductsCarousel
									class="carousel-multiple-pro-products"
									:cardType="cardType"
									:products="item.products"
									:list-id="`${listId}-tab-${n}`"
									arrowsBgHalfRounded
									v-bind="
										$b.props({
											slidesPerPage: { d: desktopSlidesPerPage, m: 2, t: 3 },
											gutter: 12,
										})
									"
								/>
							</v-tab-item>
						</v-tabs-items>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped lang="scss">
@mixin video-border {
	border-radius: 4px;
}

.video-background-overlay {
	@include video-border;

	position: absolute;
	width: 100%;
	height: 100%;
}

.video-container {
	@include video-border;

	// position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

#video-background {
	width: 100%;
	height: 100%;
	object-fit: cover;

	&.video-hosted {
		// position: absolute;
		width: 100vw;
		// height: 100vw;
		// top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);

		// &--mobile {
		// 	width: 118vw;
		// }
	}
}

.overlay-content {
	pointer-events: none;
	position: absolute;
	text-align: center;
	top: 0;
}

.title-active {
	animation: title 2s ease forwards;
}

.subtitle-active {
	animation: subtitle 2s ease forwards;
}

.btn-active {
	animation: btn 2s ease forwards;
}

@keyframes title {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	40% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes subtitle {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	50% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes btn {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	60% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.background-overlay {
	background-color: #424242aa;
}

::v-deep {
	.wide {
		button.ssr-carousel-next-button {
			right: 1%;
		}
		button.ssr-carousel-back-button {
			left: 1%;
		}
	}
	.narrow {
		button.ssr-carousel-next-button {
			right: 0%;
		}
		button.ssr-carousel-back-button {
			left: 0%;
		}
	}
}
</style>

